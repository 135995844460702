<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-row>
        <b-col cols="12">
          <b-card>
            <template v-if="dataList.length > 0">
              <batch-form
                v-for="(item,key) in dataList"
                :key="key"
                :data-key="key"
              />
            </template>
            <b-alert
              v-else
              show
              variant="info"
              class="m-2"
            >
              <div class="alert-body text-center">
                Eklenmemiş stoğunuz bulunmuyor. 👏
              </div>
            </b-alert>
          </b-card>
          <b-button
            variant="danger"
            @click="saveData"
          >
            Kaydet
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BAlert,
} from 'bootstrap-vue'
import { ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import Brands from '@/views/Sales/Stocks/Components/AddStock/Brands.vue'
import Models from '@/views/Sales/Stocks/Components/AddStock/Models.vue'
import Hardware from '@/views/Sales/Stocks/Components/AddStock/Hardware.vue'
import Fuels from '@/views/Sales/Stocks/Components/AddStock/Fuels.vue'
import Gears from '@/views/Sales/Stocks/Components/AddStock/Gears.vue'
import Colors from '@/views/Sales/Stocks/Components/AddStock/Colors.vue'
import Cc from '@/views/Sales/Stocks/Components/AddStock/Cc.vue'
import modelYear from '@/views/Sales/Stocks/Components/AddStock/Model_year.vue'
import Chasis from '@/views/Sales/Stocks/Components/AddStock/Chasis.vue'
import Engine from '@/views/Sales/Stocks/Components/AddStock/Engine.vue'
import invoiceNo from '@/views/Sales/Stocks/Components/AddStock/Invoice_no.vue'
import invoiceDate from '@/views/Sales/Stocks/Components/AddStock/Invoice_date.vue'
import purchasePrice from '@/views/Sales/Stocks/Components/AddStock/Purchase_price.vue'
import salePrice from '@/views/Sales/Stocks/Components/AddStock/Sale_price.vue'
import Location from '@/views/Sales/Stocks/Components/AddStock/Location.vue'
import State from '@/views/Sales/Stocks/Components/AddStock/State.vue'
import Pool from '@/views/Sales/Stocks/Components/AddStock/Pool.vue'
import specialStock from '@/views/Sales/Stocks/Components/AddStock/Special_stock.vue'
import StockStatuses from '@/views/Sales/Stocks/Components/AddStock/StockStatuses.vue'
import BatchForm from '@/views/Sales/Stocks/Components/BatchAddStock/batchForm.vue'

export default {
  name: 'AddStock',
  components: {
    BAlert,
    BatchForm,
    BCard,
    BRow,
    BCol,
    BButton,
    Brands,
    Models,
    Hardware,
    Fuels,
    Gears,
    Colors,
    Cc,
    modelYear,
    Chasis,
    Engine,
    invoiceNo,
    invoiceDate,
    purchasePrice,
    salePrice,
    Location,
    State,
    Pool,
    specialStock,
    StockStatuses,
    ValidationObserver,
  },
  data() {
    return {
      locale: 'tr',
      required,
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['stock/getLogoStock']
    },
    saveStatus() {
      return this.$store.getters['stock/getStockSaveStatus']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: 'Kayıt işleminiz başarılı!',
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: 'Kayıt işleminiz yapılamadı.! Lütfen tekrar deneyiniz.',
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
  },
  created() {
    localize(this.locale)
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('stock/addStockBatch')
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      }).then(resoponse => {
        if (resoponse.isConfirmed) {
          this.$router.push('/stocks/add/')
        }
      })
    },
    saveData() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$store.dispatch('stock/stockBatchSave', this.dataList)
        }
      })
    },
    getModels() {
      this.$store.dispatch('models/modelsList')
    },
  },
}
</script>
