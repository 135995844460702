<template>
  <div>
    <b-row>
      <b-col cols="12">
        <h3>{{ dataKey+1 }}.Kayıt</h3>
        <b-row>
          <b-col
            md="2"
            cols="12"
          >
            <brands />
          </b-col>
          <b-col
            md="2"
            cols="12"
          >
            <models />
          </b-col>
          <b-col
            md="4"
            cols="12"
          >
            <hardware />
          </b-col>
          <b-col
            md="2"
            cols="12"
          >
            <fuels />
          </b-col>
          <b-col
            md="2"
            cols="12"
          >
            <gears />
          </b-col>
          <b-col
            md="2"
            cols="12"
          >
            <colors />
          </b-col>
          <b-col
            md="2"
            cols="12"
          >
            <cc />
          </b-col>
          <b-col
            md="2"
            cols="12"
          >
            <model-year />
          </b-col>
          <b-col
            md="3"
            cols="12"
          >
            <chasis />
          </b-col>
          <b-col
            md="3"
            cols="12"
          >
            <engine />
          </b-col>
          <b-col
            md="3"
            cols="12"
          >
            <invoice-no />
          </b-col>
          <b-col
            md="3"
            cols="12"
          >
            <invoice-date />
          </b-col>
          <b-col
            md="3"
            cols="12"
          >
            <purchase-price is-readonly />
          </b-col>
          <b-col
            md="3"
            cols="12"
          >
            <sale-price />
          </b-col>
          <b-col
            md="4"
            cols="12"
          >
            <location />
          </b-col>
          <b-col
            md="3"
            cols="12"
          >
            <state />
          </b-col>
          <b-col
            md="2"
            cols="12"
          >
            <pool />
          </b-col>
          <b-col
            md="3"
            cols="12"
          >
            <special-stock />
          </b-col>
          <b-col
            cols="12"
          >
            <stock-statuses />
          </b-col>
        </b-row>
        <hr>
      </b-col>
    </b-row>
    <pre>{{ dataList[dataKey] }}</pre>
  </div>
</template>
<script>
import { required } from '@validations'
import {
  BButton, BCard, BCol, BRow,
} from 'bootstrap-vue'
import Brands from '@/views/Sales/Stocks/Components/BatchAddStock/Brands.vue'
import Models from '@/views/Sales/Stocks/Components/BatchAddStock/Models.vue'
import Hardware from '@/views/Sales/Stocks/Components/BatchAddStock/Hardware.vue'
import Fuels from '@/views/Sales/Stocks/Components/BatchAddStock/Fuels.vue'
import Gears from '@/views/Sales/Stocks/Components/BatchAddStock/Gears.vue'
import Colors from '@/views/Sales/Stocks/Components/BatchAddStock/Colors.vue'
import Cc from '@/views/Sales/Stocks/Components/BatchAddStock/Cc.vue'
import modelYear from '@/views/Sales/Stocks/Components/BatchAddStock/Model_year.vue'
import Chasis from '@/views/Sales/Stocks/Components/BatchAddStock/Chasis.vue'
import Engine from '@/views/Sales/Stocks/Components/BatchAddStock/Engine.vue'
import invoiceNo from '@/views/Sales/Stocks/Components/BatchAddStock/Invoice_no.vue'
import invoiceDate from '@/views/Sales/Stocks/Components/BatchAddStock/Invoice_date.vue'
import purchasePrice from '@/views/Sales/Stocks/Components/BatchAddStock/Purchase_price.vue'
import salePrice from '@/views/Sales/Stocks/Components/BatchAddStock/Sale_price.vue'
import Location from '@/views/Sales/Stocks/Components/BatchAddStock/Location.vue'
import State from '@/views/Sales/Stocks/Components/BatchAddStock/State.vue'
import Pool from '@/views/Sales/Stocks/Components/BatchAddStock/Pool.vue'
import specialStock from '@/views/Sales/Stocks/Components/BatchAddStock/Special_stock.vue'
import StockStatuses from '@/views/Sales/Stocks/Components/BatchAddStock/StockStatuses.vue'

export default {
  name: 'BatchForm',
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    Brands,
    Models,
    Hardware,
    Fuels,
    Gears,
    Colors,
    Cc,
    modelYear,
    Chasis,
    Engine,
    invoiceNo,
    invoiceDate,
    purchasePrice,
    salePrice,
    Location,
    State,
    Pool,
    specialStock,
    StockStatuses,
  },
  props: {
    dataKey: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      locale: 'tr',
      required,
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['stock/getLogoStock']
    },
  },
}

</script>
